@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typography */
h1 {
  font-size: 25px;
  font-weight: 700;
  line-height: 40px;
}

h2 {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
}

h3 {
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
}

a {
  transition: color 0.25s ease-in;
}

a:hover {
  color: #3ebdb0;
}

.container-sm {
  max-width: 1024px;
}

/* Buttons */
.btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  min-width: 160px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.25s ease-in;
}

.btn.btn-dark {
  color: #3ebdb0;
}

.btn.btn-light {
  color: #fff;
}

.btn.btn-dark:hover {
  color: #fff;
  background-color: #3ebdb0;
}

.btn.btn-light:hover {
  color: #3ebdb0;
  background-color: #fff;
}

.btn:disabled {
  opacity: 0.8;
}

.section-titles-wrp {
  text-align: center;
  margin-bottom: 40px;
}

.section-description {
  font-size: 18px;
  margin-top: 25px;
}

/* Form elements */
label {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

.field-error {
  color: red;
  font-size: 14px;
}

input,
textarea {
  width: 100%;
  border: 1px solid #22212c;
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0;
}

textarea {
  min-height: 150px;
  resize: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 42px;
    line-height: 55px;
  }

  h2 {
    font-size: 35px;
    line-height: 35px;
  }

  .btn {
    font-size: 18px;
  }
}